<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">Reset Password</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <v-form
                        ref="form"
                        v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.password"
                                        :append-icon="showPassword.password ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="showPassword.password ? 'text' : 'password'"
                                        name="input-10-2"
                                        label="Password"
                                        hint="At least 8 characters"
                                        class="input-group--focused"
                                        @click:append="showPassword.password = !showPassword.password"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.confirmPassword"
                                        :append-icon="showPassword.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="showPassword.confirmPassword ? 'text' : 'password'"
                                        name="input-10-2"
                                        label="Confirm Password"
                                        hint="At least 8 characters"
                                        class="input-group--focused"
                                        @click:append="showPassword.password = !showPassword.confirmPassword"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <div class="btn-container">
                            <v-btn class="mr-4" @click="updatePassword" color="primary">
                                Submit
                            </v-btn>
                            <v-btn @click="closeModal" color="primary" outlined>
                                Cancel
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'ResetPasswordForm',
    props: {
        staffId: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            dialog: false,
            valid: false,
            form: {
                password: '',
                confirmPassword: ''
            },
            showPassword: {
                password: false,
                confirmPassword: false
            },
            rules: {
                required: value => !!value || 'Required.',
                min: v => (v && v.length >= 8) || 'Min 8 characters'
            }
        }
    },

    methods: {
        notifySuccess (text) {
            this.$notify({
                title: 'Congratulations',
                text: text,
                type: 'success'
            })
        },
        closeModal () {
            this.form.password = ''
            this.form.confirmPassword = ''
            this.dialog = false
            this.$refs.form.reset()
        },

        async updatePassword () {
            const res = await this.axios.put(`staff-reset-password/${this.staffId}`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Updated Password')
                this.closeModal()
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
