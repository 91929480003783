<template>
    <div>
        <div class="header-page">
            <div class="header-font">
                Staff
            </div>
            <div>
                <v-btn color="primary create-btn" @click="createStaff">Create</v-btn>
            </div>
        </div>
        <div class="student-filter-container">
        </div>
        <v-data-table
            :headers="headers"
            :items="staffList"
            class="elevation-1"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                <v-icon
                    small
                    class="mr-2"
                    @click="resetPassword(item)"
                >
                    mdi-account-key
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="editStaff(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteModal(item)"
                >
                    mdi-delete
                </v-icon>
            </div>
            </template>
        </v-data-table>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
        <staff-form
            ref="staffForm"
            :mode="mode"
            :staffId="staffId"
            @close-modal="closeModal"
            @create-success="getStaffList"
            @edit-success="getStaffList"
        >
        </staff-form>
        <reset-password-form
            ref="resetForm"
            :staffId="staffId"
        >
        </reset-password-form>
    </div>
</template>

<script>
import DeleteModal from '../components/DeleteModal'
import StaffForm from '../components/StaffForm'
import ResetPasswordForm from '../components/ResetPasswordForm'
export default {
    name: 'Staff',
    components: {
        DeleteModal,
        StaffForm,
        ResetPasswordForm
    },
    data () {
        return {
            staffList: [],
            headers: [
                { text: 'Name', value: 'fullName' },
                { text: 'Username', value: 'username' },
                { text: 'Branch', value: 'branches' },
                { text: '', value: 'actions' }
            ],
            deleteForm: {
                header: 'Delete staff',
                title: 'You want to delete staff ',
                url: ''
            },
            mode: '',
            staffId: null
        }
    },

    methods: {
        async getStaffList () {
            const res = await this.axios.get('staff')
            if (res && res.data && res.data.status === 'ok') {
                this.staffList = res.data.data
            }
        },

        createStaff () {
            this.$refs.staffForm.dialog = true
            this.mode = 'create'
        },

        editStaff (item) {
            this.$refs.staffForm.dialog = true
            this.mode = 'edit'
            this.staffId = item.id
        },

        resetPassword (item) {
            this.$refs.resetForm.dialog = true
            this.staffId = item.id
        },

        closeModal () {
            this.mode = ''
            this.staffId = null
        },

        goToCourseList (item) {
            this.$router.push({ path: `/students/${item.id}/courses` })
        },

        deleteModal (item) {
            console.log(item)
            this.deleteForm.title = `You want to delete student ${item.fullName} ?`
            this.deleteForm.url = `staff/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete staff success',
                type: 'success'
            })
            await this.getStaffList()
        }
    },

    async mounted () {
        await this.getStaffList()
    }
}
</script>

<style lang="scss" scoped>
.student-filter-container {
    display: flex;
    align-items: center;

    .filter-search {
        width: 220px;
    }
}
</style>
