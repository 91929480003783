<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">{{headerForm}}</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <v-form
                        ref="form"
                        v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.firstName"
                                        :rules="inputRules"
                                        label="First Name"
                                        :error-messages="errors.firstName"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.lastName"
                                        :rules="inputRules"
                                        label="Last Name"
                                        :error-messages="errors.lastName"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-select
                                        v-model="form.branchIds"
                                        :items="branchOptions"
                                        label="Branch"
                                        item-text="key"
                                        item-value="value"
                                        :error-messages="errors.branchIds"
                                        multiple
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.username"
                                        :rules="inputRules"
                                        label="Username"
                                        :error-messages="errors.username"
                                        required
                                        :disabled="this.mode === 'edit'"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row v-if="mode === 'create'">
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.password"
                                        :append-icon="showPassword.password ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="showPassword.password ? 'text' : 'password'"
                                        name="input-10-2"
                                        label="Password"
                                        hint="At least 8 characters"
                                        class="input-group--focused"
                                        @click:append="showPassword.password = !showPassword.password"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-text-field
                                        v-model="form.confirmPassword"
                                        :append-icon="showPassword.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, rules.min]"
                                        :type="showPassword.confirmPassword ? 'text' : 'password'"
                                        name="input-10-2"
                                        label="Confirm Password"
                                        hint="At least 8 characters"
                                        class="input-group--focused"
                                        @click:append="showPassword.password = !showPassword.confirmPassword"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <div class="btn-container">
                            <v-btn class="mr-4" @click="submit" color="primary">
                                Submit
                            </v-btn>
                            <v-btn color="primary" @click="closeModal" outlined>
                                Cancel
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: 'StaffForm',

    props: {
        staffId: {
            type: Number,
            default: null
        },

        mode: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            dialog: false,
            valid: false,
            form: {
                firstName: '',
                lastName: '',
                username: '',
                password: '',
                confirmPassword: '',
                branchIds: []
            },
            showPassword: {
                password: false,
                confirmPassword: false
            },
            inputRules: [
                v => !!v || 'This field is required'
            ],
            rules: {
                required: value => !!value || 'Required.',
                min: v => (v && v.length >= 8) || 'Min 8 characters'
            },
            branchOptions: [],
            headerForm: 'Staff',
            errors: {}
        }
    },
    methods: {
        notifySuccess (text) {
            this.$notify({
                title: 'Congratulations',
                text: text,
                type: 'success'
            })
        },

        async submit () {
            if (!this.$refs.form.validate()) {
                return
            }
            if (this.mode === 'create') {
                await this.createStaff()
            } else if (this.mode === 'edit') {
                await this.editStaff()
            }
        },

        async getBranchOptions () {
            const res = await this.axios.get('branches-select')
            if (res && res.data && res.data.status === 'ok') {
                this.branchOptions = res.data.data
            }
        },

        async createStaff () {
            const res = await this.axios.post('staff', this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Create staff success')
                this.$emit('create-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async editStaff () {
            const res = await this.axios.put(`staff/${this.staffId}`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Edit staff success')
                this.$emit('edit-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async getStaffData () {
            const res = await this.axios.get(`staff/${this.staffId}`)
            if (res && res.data && res.data.status === 'ok') {
                const staffData = res.data.data
                this.form = {
                    ...this.form,
                    username: staffData.username,
                    branchIds: staffData.branchIds,
                    firstName: staffData.firstName,
                    lastName: staffData.lastName
                }
            }
        },

        closeModal () {
            this.dialog = false
            this.resetForm()
            this.$emit('close-modal')
        },

        resetForm () {
            this.$refs.form.reset()
        }
    },
    async mounted () {
        await this.getBranchOptions()
    },

    watch: {
        async mode (newVal, oldVal) {
            if (newVal === 'create') {
                this.headerForm = 'Create staff'
            } else if (newVal === 'edit') {
                this.headerForm = 'Edit staff'
                await this.getStaffData()
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
